.App, #twitch-embed {
  text-align: center;
}

.App-logo {
  float: left;
  margin: 25px -25px 0 25px;
  max-width: 14vh;
  max-height: 14vh;
  pointer-events: none;
  border-radius: 10vmin;
  box-shadow: 3px 3px grey;
}

body {
  background-color: sandybrown;
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-shadow: 1px 1px black;
}

.App-header {
  margin-top: 50px;
}

.MainTitle {
  font-family: Potato;
  color: #d81432;
  font-size: 80px;
  margin-bottom: -20px;
  text-shadow: 3px 3px #580000;
}

.SubTitle {
  font-family: Potato;
  color: #00d300;
  font-size: 50px;
  text-shadow: 3px 3px #003d00;
}

a img { height: 20px; }
h5 { margin-block-end: 0; margin: 0; }

.button {
  background-color: sandybrown;
  border: none;
  border-radius: 15px;
  color: white;
  padding: 7px 6px 3px 6px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 9px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  text-align: center;
}

@font-face {
  font-family: Potato;
  src: url(Potato_sans-Black.otf);
}

.ProgressBars {
  width: 75%;
  display: inline-block;
  margin-bottom: 25px;
}

.green {
  color: #fff !important;
  background-color: #4CAF50 !important;
  width: 0%;
}

.red {
  color: #fff !important;
  background-color: #f44336 !important;
  width: 0%;
}

.blue {
  color: #fff !important;
  background-color: #2196F3 !important;
  width: 0%;
}

.indigo {
    color: #fff !important;
    background-color: #3f51b5 !important;
    width: 0%;
}

.light-grey {
  color: #000 !important;
  background-color: #f1f1f1 !important;
  margin-bottom: 10px;
}

#twitch-player {
  display: inline-block;
}

h3 {
  margin-bottom: 0;
}